import 'slick-carousel';

jQuery( document ).ready(function($) {

  // popup
  $('.popup-trigger').click(function(){
    const theTarget = $(this).attr('href');
    $(`.popup${theTarget}`).addClass('active');
  })

  $('.close').click(function(){
    $(this).parent().parent().parent().removeClass('active');
  })

  // sliders
  $('.slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>',
  });

  // form
  $("#jharrel-nl").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        $('form, .form-alert.error').hide()
        $('.form-alert.success').show()
      },
      error: function (err) {
        $('.form-alert.error').show()
      }
    });
  });


});